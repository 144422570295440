import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Layout, Row, Space } from "antd";

import DateNavigator from "./DateNavigator";
import usePageHeader from "./usePageHeader";
import NotificationsDrawer from "../NotificationsDrawer";

const { Header } = Layout;

const PageHeader = () => {
  const {
    ddItems,
    user,
    dashboardLayout,
    actionButtonsLeftJsx,
    actionButtonsRightJsx,
    dashboardSelectsJsx,
    dashboardTagsJsx,
    timezone,
    screens,
    onDateChange,
    toggleNotificationDrawer,
    isNotificationDrawerVisible,
  } = usePageHeader();

  return (
    <>
      <NotificationsDrawer
        open={isNotificationDrawerVisible}
        onClose={toggleNotificationDrawer}
      />
      <Header className="page-header">
        <Row className="flex-1" justify={"center"}>
          {/* left side */}
          <Col xs={14} lg={10} className="page-header-left">
            {dashboardLayout?.isHeaderRequired && (
              <h1 className="font-monstserrat page-title m-0">
                {dashboardLayout.boardInfo?.facilityName?.toLowerCase()}{" "}
                {dashboardLayout?.pageTitle}
              </h1>
            )}
            {dashboardSelectsJsx}
            {dashboardTagsJsx}
            {actionButtonsLeftJsx}
          </Col>

          {/* center side */}
          {screens.lg && (
            <Col span={4} className="page-header-center">
              {dashboardLayout?.boardInfo?.boardDate && (
                <DateNavigator
                  calendarDate={dashboardLayout?.boardInfo?.boardDate}
                  timezone={timezone}
                  onDateChange={onDateChange}
                />
              )}
            </Col>
          )}

          {/* right side */}
          <Col span={10} className="page-header-right">
            {actionButtonsRightJsx}
            <Dropdown
              menu={{
                items: ddItems,
              }}
              trigger={["click"]}
            >
              <Space className="cursor-pointer">
                <Avatar
                  size={"large"}
                  className="user-avatar"
                  icon={<UserOutlined className="text-medium" />}
                />
                <div className="user-info">
                  <h4>{user?.name}</h4>
                  <div className="role">{user?.role}</div>
                </div>
              </Space>
            </Dropdown>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default PageHeader;

export const GET_ROOMS = "room/list",
  GET_PROVIDERS = "provider/list",
  GET_PROCEDURES = "procedure/list",
  GET_SCHEDULES = "schedule/list/all",
  GET_END_OF_DAY_CASES = "procedure/end-of-day-cases",
  MANAGE_SCHEDULE = "schedule/manage",
  GET_UNSCHEDULED_PROCEDURES = "schedule/list/unassigned",
  GET_FACILITIES = "facilities/list",
  GET_SURGEONS = "surgeon/list",
  ADD_CASE = "procedure/create",
  GET_CASE = "procedure/get-case",
  UPDATE_CASE = "procedure/edit-case",
  ADD_SURGEON_BLOCK_TIMINGS = "surgeon/block-timings",
  GET_SURGEON_BLOCK_TIMINGS = "surgeon/block-timings-form-data",
  GET_SURGEON_BLOCK_TIMINGS_BOARD = "surgeon/all-surgeon-schedules",
  GET_SITES = "room/list/site",
  GET_SITE_ROOMS = "room/list/room-no",
  GET_FACILITY_DETAIL = "procedure/get-case-count",
  GET_GO_HOME_LIST = "provider/get-go-home-list",
  EDIT_GOT_HOME_LIST = "provider/edit-go-home-list",
  GET_PROVIDER_MESSAGES = "provider/providers-messages-list",
  GET_SHIFTS = "shifts",
  UPDATE_SHIFT = "shifts/update-shift",
  REVERT_MULTIPLE_SHIFT_PROVIDERS = "shifts/revert-temp-provider",
  UNASSIGN_PROVIDER_FROM_SHIFT="shifts/unassign-provider",
  ASSIGN_PROVIDER_TO_SHIFT = "shifts/assign-provider",
  GET_PROVIDER_BY_FACILITY = "provider/all",
  GET_SHIFT_CHANGE_REQUEST_COUNT = "shifts/get-shift-change-request",
  UPDATE_PROVIDER_REQUEST_COUNT = "provider/providers-change-request",
  GET_POSITIONS_BY_FACILITY_ID = (facilityId) =>
    `shifts/get-position-strings?facilityId=${facilityId}`,
  CREATE_MULTIPLE_SHIFTS = "shifts/create-shifts",
  CREATE_EXTRA_SHIFTS = "shifts/create-extra-shifts",
  SWAP_OR_TRANSFER_PROVIDER = "shifts/swap-or-transfer-providers",
  DELETE_MULTIPLE_SHIFTS = "shifts/delete-multiple-shifts",
  DELETE_HOSPITAL_SHIFT = "shifts/hospital-shift-delete",
  GET_PROVIDER_SCHEDULES = "provider/get-off-providers",
  GET_PROVIDERS_FOR_SWAP_OR_TRANSFER =
    "provider/get-providers-for-swap-or-transfer",
  GET_BOARD_RUNNERS = "schedule/get-board-runner",
  ASSIGN_BOARD_RUNNER = "schedule/assign-board-runner",
  UNASSIGN_BOARD_RUNNER = "schedule/unassign-board-runner",
  GET_NOTIFICATIONS = "provider/get-notifications",
  MAANGE_SWAP_OR_TRANSFER = "shifts/manage-swap-or-transfer",
  GET_CONFLICTING_SHIFTS = "shifts/get-conflicting-shifts",
  GET_PROVIDER_COUNT = "procedure/forecasted",
  // auth
  TOUR_COMPLETE = "auth/tour-complete",
  REIGSTER = "auth/register",
  LOGIN = "auth/login",
  ME = "auth/me",
  // user
  UPDATE_PROFILE = "user/update";

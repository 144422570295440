import { DEFAULT_ACTIONS } from "abilities/definitions";
import { Result } from "antd";
import { Can } from "contexts/can";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  resetDashboardHeaderProps,
  setDashboardHeaderProps,
} from "services/redux/slices/layout";
import { ShiftsProvider } from "../contexts/shiftContext";

const WithHeaderAndPermissions = ({
  pageTitle = "",
  Component,
  isHeaderRequired = true,
  module,
  elementProps,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const dispatchPayload = {
      pageTitle,
      isHeaderRequired,
    };

    dispatch(setDashboardHeaderProps(dispatchPayload));

    return () => {
      dispatch(resetDashboardHeaderProps());
    };
  }, [pageTitle, isHeaderRequired, dispatch]);

  return (
    <Can I={DEFAULT_ACTIONS.VIEW} a={module} passThrough>
      {(allowed) =>
        allowed ? (
          // Wrap the Component with ShiftsProvider
          <ShiftsProvider>
            <Component {...elementProps} />
          </ShiftsProvider>
        ) : (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
          />
        )
      }
    </Can>
  );
};

export default WithHeaderAndPermissions;

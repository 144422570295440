import { combineReducers } from "@reduxjs/toolkit";
import { user, layout, notification, calendarDate } from "./slices";

const rootReducer = combineReducers({
  user,
  layout,
  notification,
  calendarDate,
});

export default rootReducer;

import { createSlice } from "@reduxjs/toolkit";

const initialSlice = {
  dashboardLayout: {
    pageTitle: "",
    headerContent: [],
    isHeaderRequired: false,
    buttons: [],
    tags: [],
    selects: [],
    boardInfo: {
      onDateChange: null,
      boardDate: null,
      facilityName: "",
      facilityId: null,
    },
  },
};

const layoutSlice = createSlice({
  name: "layout",
  initialState: initialSlice,
  reducers: {
    setDashboardHeaderProps: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.dashboardLayout[key] = action.payload[key];
      });
    },

    setFacilityInfo: (state, action) => {
      state.dashboardLayout.boardInfo.facilityId = action.payload.facilityId;
      state.dashboardLayout.boardInfo.facilityName =
        action.payload.facilityName;
    },
    setBoardDate: (state, action) => {
      state.dashboardLayout.boardInfo.boardDate = action.payload;
    },
    setBoardDateChangeHandler: (state, action) => {
      state.dashboardLayout.boardInfo.onDateChange = action.payload;
    },
    resetDashboardHeaderProps: () => ({ ...initialSlice }),
  },
});

export const {
  setPageLayout,
  setDashboardHeaderProps,
  setBoardDate,
  setFacilityInfo,
  resetDashboardHeaderProps,
  setBoardDateChangeHandler,
} = layoutSlice.actions;

export default layoutSlice.reducer;

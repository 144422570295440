import React, { createContext, useState } from "react";

// Create a context
export const ShiftsContext = createContext();

// Create a provider component
export const ShiftsProvider = ({ children }) => {
  const [shiftList, setShifts] = useState([]);

  return (
    <ShiftsContext.Provider value={{ shiftList, setShifts }}>
      {children}
    </ShiftsContext.Provider>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const calendarSlice = createSlice({
  name: "calendarDate",
  initialState: {
    selectedDate: null,
  },
  reducers: {
    setDateRange: (state, action) => {
      state.selectedDate = action.payload.selectedDate;
    },
  },
});

export const { setDateRange } = calendarSlice.actions;
export default calendarSlice.reducer;
